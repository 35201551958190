<template>
  <v-list class="PA-2">
    <v-list-item v-if="user && isAdmin">
      <r-btn plain :to="{ name: 'Company' }">
        <v-icon left>mdi-domain</v-icon>
        {{ $t('FrontPageAdminItems.Companies') }}
      </r-btn>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'user-menu',
  computed: {
    ...mapGetters(['user', 'isAdmin', 'isManager']),
  },
};
</script>

<style></style>