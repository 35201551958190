<template>
  <div>
    <div v-if="user" class="text-center">
      Logget ind som <br />
      <strong>{{ user.name }}</strong>
    </div>
    <v-list>
      <div v-if="$vuetify.breakpoint.mobile">
        <v-list-item v-if="user" :to="{ name: 'FrontPage' }">
          <v-list-item-title>
            <v-icon left>mdi-application-outline</v-icon>
            {{ $t('FrontPageUserItems.FrontPage') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user && !$vuetify.breakpoint.mobile" :to="{ name: 'Project' }" exact>
          <v-list-item-title>
            <v-icon left>mdi-file-tree</v-icon>
            {{ $t('FrontPageUserItems.ProjectHandling') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user" :to="{ name: 'User' }">
          <v-list-item-title>
            <v-icon left>mdi-account-box-multiple</v-icon>
            {{ $t('FrontPageUserItems.Users') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user" :to="{ name: 'Projects' }">
          <v-list-item-title>
            <v-icon left>mdi-folder-cog-outline</v-icon>
            {{ $t('FrontPageUserItems.ProjectHandling') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user && isAdmin" :to="{ name: 'Company' }">
          <v-list-item-title>
            <v-icon left>mdi-domain</v-icon>
            {{ $t('FrontPageAdminItems.Companies') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user && isManager" :to="{ name: 'MyCompany' }">
          <v-list-item-title>
            <v-icon left>mdi-domain</v-icon>
            {{ $t('FrontPageAdminItems.MyCompany') }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-if="user" class="my-2" />
      </div>
      <v-list-item v-if="user" @click="$emit('show-changePassword')">
        <v-list-item-title>
          <v-icon left>mdi-lock-reset</v-icon>
          Change Password
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="user" @click="logout()">
        <v-list-item-title>
          <v-icon left>mdi-logout</v-icon>
          Log ud
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="$emit('show-login')">
        <v-list-item-title>
          <v-icon left>mdi-login</v-icon>
          Log ind
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'user-menu',
  computed: {
    ...mapGetters(['user', 'isAdmin', 'isManager']),
  },
  methods: {
    logout() {
      this.$router.push({ name: 'FrontPage' });
      this.$store.dispatch('LOGOUT');
    },
  },
};
</script>

<style></style>
