<template>
  <v-container :fluid="!$vuetify.breakpoint.mobile" :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''">
    <v-app-bar clipped-left app color="navbar" class="font-weight-medium" elevation="0">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="2" v-if="!$vuetify.breakpoint.mobile">
          <h2>Risky Business</h2>
        </v-col>
        <v-col cols="4" v-else @click="goToFrontPage">
          <h2>RB</h2>
        </v-col>
        <v-col cols="8" class="text-center" justify="center" v-if="!$vuetify.breakpoint.mobile">
          <div v-if="!$vuetify.breakpoint.mobile && user !== null">
            <r-btn class="mr-2" plain :to="{ name: 'FrontPage' }">
              <v-icon class="mr-2">mdi-application-outline</v-icon>
              {{ $t('FrontPageUserItems.FrontPage') }}</r-btn
            >
            <v-menu open-on-hover :open-on-click="false" offset-y bottom right>
              <template v-slot:activator="{ on, attrs }">
                <r-btn class="mr-2" :class="checkRoute ? 'v-btn--active' : ''" plain @click="changeProject()" v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-file-tree</v-icon> {{ $t('FrontPageUserItems.ProjectHandling') }}
                </r-btn>
              </template>
              <v-list>
                <v-list-item-title class="px-0 mx-0" v-for="(project, index) in projectList" :key="index">
                  <r-btn plain :to="{ name: 'Project', params: { projectId: project.id } }">
                    <color-range-field class="mr-2" :itemCount="project.riskCount" :value="0" />
                    {{ project.name }}
                  </r-btn>
                </v-list-item-title>
              </v-list>
            </v-menu>

            <r-btn class="mr-2" plain :to="{ name: 'User' }" v-if="isManager">
              <v-icon class="mr-2">mdi-account-box-multiple</v-icon>

              {{ $t('FrontPageUserItems.Users') }}</r-btn
            >
            <r-btn class="mr-2" plain :to="{ name: 'Projects' }">
              <v-icon class="mr-2">mdi-folder-cog-outline</v-icon>
              {{ $t('FrontPageUserItems.ProjectHandling') }}</r-btn
            >
            <v-menu offset-y bottom left v-if="isAdmin">
              <template v-slot:activator="{ on, attrs }">
                <r-btn plain title="Admin Pannel" v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-arrow-down-drop-circle</v-icon>
                  {{ $t('FrontPageAdminItems.AdminOptions') }}
                </r-btn>
              </template>
              <v-card>
                <v-card-text class="pa-1"> <AdminMenu></AdminMenu> </v-card-text>
              </v-card>
            </v-menu>

            <r-btn class="mr-2" plain :to="{ name: 'MyCompany' }">
              <v-icon class="mr-2">mdi-domain</v-icon>
              {{ $t('FrontPageAdminItems.MyCompany') }}</r-btn
            >
          </div>
        </v-col>
        <v-col cols="8" align="right" sm="2">
          <v-menu :close-on-content-click="false" offset-y bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed large title="Indstillinger" icon v-bind="attrs" v-on="on" color="activeButton">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-sheet height="200" width="200">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-col cols="12" class="text-center">
                    <v-btn depressed large title="Sprog" icon v-bind="attrs" v-on="on" color="activeButton">
                      <v-icon>mdi-earth</v-icon>
                      {{ $i18n.locale }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-checkbox
                      color="info"
                      @click="handleThemeChange"
                      v-model="$vuetify.theme.dark"
                      hint="This toggles between dark and light theme."
                      inset
                      label="Enable dark theme"
                      persistent-hint
                    />
                  </v-col>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in langList" :key="index">
                    <v-list-item-title>
                      <v-btn @click="setLanguage(item.lang)">
                        {{ item.lang }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-sheet>
          </v-menu>
          <v-btn @click="changeProject" icon v-if="$vuetify.breakpoint.mobile" :color="user ? 'activeButton' : 'button'">
            <v-icon>mdi-file-tree</v-icon>
          </v-btn>
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed large title="Brugerinfo" icon v-bind="attrs" v-on="on" :color="user ? 'activeButton' : 'button'">
                <v-icon v-if="!$vuetify.breakpoint.mobile">mdi-account-circle</v-icon>
                <v-icon v-else>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <user-menu @show-login="showLoginDialog = true" @show-changePassword="$refs.ChangePassRef.changePassword(false)" />
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-dialog v-model="showLoginDialog" width="500">
      <login @hide-login="showLoginDialog = false" />
    </v-dialog>
    <change-password-dialog ref="ChangePassRef" />
    <div class="pa-0">
      <v-bottom-navigation style="background-color: #1e1e1e" v-if="$vuetify.breakpoint.mobile && checkRoute">
        <r-btn class="mb-2" plain :to="{ name: 'RiskList' }" exact>
          <v-icon class="mr-2">mdi-page-next</v-icon>
        </r-btn>
        <r-btn class="mb-2" plain :to="{ name: 'RiskTriangle' }" exact>
          <v-icon class="mr-2">mdi-triangle-outline</v-icon>
        </r-btn>
        <r-btn class="mb-2" plain :to="{ name: 'rapports' }" exact>
          <v-icon class="mr-2">mdi-post-outline</v-icon>
        </r-btn>
        <r-btn class="ml-1 mb-2" plain :to="{ name: 'ImpactLevel' }" exact>
          <v-icon class="mr-2">mdi-page-previous-outline</v-icon>
        </r-btn>
      </v-bottom-navigation>
      <v-main fluid :class="$vuetify.breakpoint.mobile ? 'pa-4' : 'pl-0 pt-8'">
        <router-view />
      </v-main>
    </div>
  </v-container>
</template>

<script>
import Login from '../components/Login.vue';
import UserMenu from '../components/UserMenu.vue';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import AdminMenu from '../components/AdminMenu.vue';
import ColorRangeField from '@/components/ColorRangeField.vue';
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';

export default {
  name: 'Home',
  components: {
    UserMenu,
    ChangePasswordDialog,
    Login,
    AdminMenu,
    ColorRangeField,
  },
  data: () => ({
    showLoginDialog: false,
    firstProject: {},
  }),
  watch: {
    [`$route.params.projectId`]: {
      immediate: true,
      handler(val) {
        val ? this.$store.dispatch('SET_PROJECT_ID', val) : null;
      },
    },
    isAuthenticated: function () {
      this.getProjects();
    },
  },
  computed: {
    ...mapGetters(['user', 'isAuthenticated', 'isAdmin', 'isManager', 'langList', 'darkMode', 'currentProjectId', 'projectList']),

    checkRoute() {
      return this.$route.matched.some((x) => x.path.includes('/project/'));
    },
  },
  methods: {
    changeProject() {
      if (!this.checkRoute) {
        if (this.currentProjectId) {
          this.$router.push({ name: 'Project', params: { projectId: this.currentProjectId } });
        } else if (this.projectList.length > 0) {
          this.$router.push({ name: 'Project', params: { projectId: this.projectList[0].id } });
        }
      }
    },

    goToFrontPage() {
      this.isInProject = false;
      this.$router.push({ name: 'FrontPage' });
    },

    async getProjects() {
      if (this.isAuthenticated) {
        let projects = await apiService.getProjects();
        this.$store.dispatch('SET_PROJECTLIST', projects);
        if (this.$vuetify.breakpoint.mobile) {
          this.firstProject = this.projectList[0];
        }
      }
    },

    getMaxConsequence(project) {
      let highestRiskIndex = [];
      project.risks.forEach((risk) => {
        highestRiskIndex.push(Math.max(...[risk.resource, risk.scope, risk.expenses, risk.timeLine]));
      });
      return highestRiskIndex;
    },

    updateCurrentProject() {
      this.$store.dispatch('SET_PROJECT_ID', this.$route.params.projectId);
    },

    setLanguage(language) {
      this.$i18n.locale = language;
      this.$store.dispatch('SET_LANGUAGE', language);
    },

    handleThemeChange() {
      this.$store.dispatch('SET_DARKMODE', !this.darkMode);
    },
  },
  created() {
    this.getProjects();
  },
};
</script>
