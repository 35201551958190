<template>
  <span :class="shouldbeGray ? $util.colorize(0) : $util.colorize(value)" class="d-flex justify-center white--text font-weight-bold" style="width: 30px">
    <div v-if="itemCount">{{ itemCount }}</div>
    <div v-else>{{ value }}</div>
  </span>
</template>

<script>
export default {
  name: 'ColorRangeField',
  props: {
    shouldbeGray: Boolean,
    value: Number,
    itemCount: Number,
  },
};
</script>

<style></style>
