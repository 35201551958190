<template>
  <v-form ref="loginForm" v-model="isValid" @submit="validate">
    <v-card v-if="!forgotPasswordView">
      <v-card-title>Log ind</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="px-2">
            <v-text-field v-model.trim="username" :rules="[rules.required]" label="Brugernavn" @ />
          </v-col>
          <v-col cols="12" md="6" class="px-2">
            <v-text-field
              v-model="password"
              label="Kodeord"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-alert type="error" v-if="errorMessage">
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <r-btn block :disabled="!isValid" color="success" class="mr-4 mb-4" type="submit" :loading="isLoading"> Log ind </r-btn>
          <r-btn block color="gray" class="mt-4" @click="forgotPasswordView = true"> Reset password </r-btn>
        </v-col>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>Reset password</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" class="px-2">
            <v-text-field v-model.trim="username" :rules="[rules.required]" label="Username" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-expand-transition>
              <v-alert type="error" v-if="errorMessage">
                {{ errorMessage }}
              </v-alert>
            </v-expand-transition>
            <v-expand-transition>
              <v-alert type="info" v-if="emailSent"> You will shortly receive an email with a link for resetting your password. </v-alert>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <r-btn block :disabled="!isValid" v-if="!emailSent" color="success" class="mr-4 mb-4" @click="sendEmail" :loading="isLoading">
            Send password reset email
          </r-btn>
          <r-btn block soft class="mr-4" @click="loginView">Cancel</r-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: 'login',
  data: () => ({
    username: null,
    password: null,
    isValid: false,
    isLoading: false,
    showPassword: false,
    errorMessage: null,
    emailSent: false,
    forgotPasswordView: false,
    rules: {
      required: (value) => !!value || 'Du skal indtaste en værdi.',
    },
  }),
  methods: {
    validate(e) {
      if (this.$refs.loginForm.validate()) {
        this.isLoading = true;
        this.$store
          .dispatch('AUTHENTICATE', {
            username: this.username,
            password: this.password,
          })
          .then(() => {
            this.isLoading = false;
            this.$emit('hide-login');
            this.$router.push({ name: 'FrontPage' });
          })
          .catch(() => {
            this.isLoading = false;
            this.errorMessage = 'Fejl - du kunne ikke logges ind i systemet';
          });
      }

      // we need to do this in order to not actually submit the form (which will reload the site)
      e.preventDefault();
    },
    loginView() {
      this.forgotPasswordView = false;
      this.password = '';
    },
    sendEmail() {
      this.isLoading = true;
      this.errorMessage = null;
      this.$store
        .dispatch('RESET_PASSWORD', this.username)
        .then((result) => {
          if (!result) {
            this.errorMessage = 'Unknown username';
          } else {
            this.emailSent = true;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.errorMessage = 'Unable to reset password';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
