var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.$vuetify.breakpoint.mobile ? 'pa-0' : '',attrs:{"fluid":!_vm.$vuetify.breakpoint.mobile}},[_c('v-app-bar',{staticClass:"font-weight-medium",attrs:{"clipped-left":"","app":"","color":"navbar","elevation":"0"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"cols":"2"}},[_c('h2',[_vm._v("Risky Business")])]):_c('v-col',{attrs:{"cols":"4"},on:{"click":_vm.goToFrontPage}},[_c('h2',[_vm._v("RB")])]),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"8","justify":"center"}},[(!_vm.$vuetify.breakpoint.mobile && _vm.user !== null)?_c('div',[_c('r-btn',{staticClass:"mr-2",attrs:{"plain":"","to":{ name: 'FrontPage' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-application-outline")]),_vm._v(" "+_vm._s(_vm.$t('FrontPageUserItems.FrontPage')))],1),_c('v-menu',{attrs:{"open-on-hover":"","open-on-click":false,"offset-y":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('r-btn',_vm._g(_vm._b({staticClass:"mr-2",class:_vm.checkRoute ? 'v-btn--active' : '',attrs:{"plain":""},on:{"click":function($event){return _vm.changeProject()}}},'r-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-tree")]),_vm._v(" "+_vm._s(_vm.$t('FrontPageUserItems.ProjectHandling'))+" ")],1)]}}],null,false,1354621259)},[_c('v-list',_vm._l((_vm.projectList),function(project,index){return _c('v-list-item-title',{key:index,staticClass:"px-0 mx-0"},[_c('r-btn',{attrs:{"plain":"","to":{ name: 'Project', params: { projectId: project.id } }}},[_c('color-range-field',{staticClass:"mr-2",attrs:{"itemCount":project.riskCount,"value":0}}),_vm._v(" "+_vm._s(project.name)+" ")],1)],1)}),1)],1),(_vm.isManager)?_c('r-btn',{staticClass:"mr-2",attrs:{"plain":"","to":{ name: 'User' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-box-multiple")]),_vm._v(" "+_vm._s(_vm.$t('FrontPageUserItems.Users')))],1):_vm._e(),_c('r-btn',{staticClass:"mr-2",attrs:{"plain":"","to":{ name: 'Projects' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-folder-cog-outline")]),_vm._v(" "+_vm._s(_vm.$t('FrontPageUserItems.ProjectHandling')))],1),(_vm.isAdmin)?_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('r-btn',_vm._g(_vm._b({attrs:{"plain":"","title":"Admin Pannel"}},'r-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-down-drop-circle")]),_vm._v(" "+_vm._s(_vm.$t('FrontPageAdminItems.AdminOptions'))+" ")],1)]}}],null,false,1618570044)},[_c('v-card',[_c('v-card-text',{staticClass:"pa-1"},[_c('AdminMenu')],1)],1)],1):_vm._e(),_c('r-btn',{staticClass:"mr-2",attrs:{"plain":"","to":{ name: 'MyCompany' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-domain")]),_vm._v(" "+_vm._s(_vm.$t('FrontPageAdminItems.MyCompany')))],1)],1):_vm._e()]):_vm._e(),_c('v-col',{attrs:{"cols":"8","align":"right","sm":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","large":"","title":"Indstillinger","icon":"","color":"activeButton"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}])},[_c('v-sheet',{attrs:{"height":"200","width":"200"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","large":"","title":"Sprog","icon":"","color":"activeButton"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-earth")]),_vm._v(" "+_vm._s(_vm.$i18n.locale)+" ")],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"color":"info","hint":"This toggles between dark and light theme.","inset":"","label":"Enable dark theme","persistent-hint":""},on:{"click":_vm.handleThemeChange},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)]}}])},[_c('v-list',_vm._l((_vm.langList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-btn',{on:{"click":function($event){return _vm.setLanguage(item.lang)}}},[_vm._v(" "+_vm._s(item.lang)+" ")])],1)],1)}),1)],1)],1)],1),(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{attrs:{"icon":"","color":_vm.user ? 'activeButton' : 'button'},on:{"click":_vm.changeProject}},[_c('v-icon',[_vm._v("mdi-file-tree")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","large":"","title":"Brugerinfo","icon":"","color":_vm.user ? 'activeButton' : 'button'}},'v-btn',attrs,false),on),[(!_vm.$vuetify.breakpoint.mobile)?_c('v-icon',[_vm._v("mdi-account-circle")]):_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-card',[_c('v-card-text',[_c('user-menu',{on:{"show-login":function($event){_vm.showLoginDialog = true},"show-changePassword":function($event){return _vm.$refs.ChangePassRef.changePassword(false)}}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showLoginDialog),callback:function ($$v) {_vm.showLoginDialog=$$v},expression:"showLoginDialog"}},[_c('login',{on:{"hide-login":function($event){_vm.showLoginDialog = false}}})],1),_c('change-password-dialog',{ref:"ChangePassRef"}),_c('div',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mobile && _vm.checkRoute)?_c('v-bottom-navigation',{staticStyle:{"background-color":"#1e1e1e"}},[_c('r-btn',{staticClass:"mb-2",attrs:{"plain":"","to":{ name: 'RiskList' },"exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-page-next")])],1),_c('r-btn',{staticClass:"mb-2",attrs:{"plain":"","to":{ name: 'RiskTriangle' },"exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-triangle-outline")])],1),_c('r-btn',{staticClass:"mb-2",attrs:{"plain":"","to":{ name: 'rapports' },"exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-post-outline")])],1),_c('r-btn',{staticClass:"ml-1 mb-2",attrs:{"plain":"","to":{ name: 'ImpactLevel' },"exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-page-previous-outline")])],1)],1):_vm._e(),_c('v-main',{class:_vm.$vuetify.breakpoint.mobile ? 'pa-4' : 'pl-0 pt-8',attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }